<template>
    <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_42_548)">
<g clip-path="url(#clip0_42_548)">
<path d="M37 4H4V25H37V4Z" fill="#2E4593"/>
<path d="M21.0611 8.77179L21.3888 9.70803L20.6043 9.12414V9.11407V9.12414L19.8198 9.70803L20.1475 8.77179L20.1574 8.76172H20.1475L19.3431 8.2181H20.3163L20.5844 7.27179L20.8824 8.2181H21.8655L21.0512 8.76172L21.0611 8.77179Z" fill="white"/>
<path d="M24.3084 10.302L24.6362 11.2382L23.8616 10.6544L23.8516 10.6443V10.6544L23.0671 11.2382L23.3948 10.302L23.4047 10.2919L22.6003 9.74831H23.5636L23.8318 8.802L24.1297 9.74831H24.1396H25.1128L24.3084 10.2919V10.302Z" fill="white"/>
<path d="M17.9627 10.302L18.2904 11.2382L17.5058 10.6544V10.6443L17.4959 10.6544L16.7114 11.2382L17.049 10.302V10.2919L16.2446 9.74831H17.2178L17.476 8.802L17.7839 9.74831H17.774H17.7839H18.7571L17.9527 10.2919L17.9627 10.302Z" fill="white"/>
<path d="M15.8276 13.0302L16.1553 13.9665L15.3708 13.3725L14.5862 13.9665L14.914 13.0302L14.9239 13.0201H14.914L14.1096 12.4665H15.0828L15.3509 11.5302L15.6488 12.4665V12.4765V12.4665H16.632L15.8177 13.0201L15.8276 13.0302Z" fill="white"/>
<path d="M15.8276 16.5235L16.1553 17.4698L15.3708 16.8759L14.5862 17.4698L14.914 16.5235H14.9239H14.914L14.1096 15.9698H15.0828L15.3509 15.0336L15.6488 15.9698H16.632L15.8177 16.5235H15.8276Z" fill="white"/>
<path d="M17.9627 19.1913L18.2904 20.1376L17.5058 19.5436H17.4959L16.7114 20.1376L17.049 19.1913L16.2446 18.6376H17.2178L17.476 17.7014L17.7839 18.6376H17.774H17.7839H18.7571L17.9527 19.1913H17.9627Z" fill="white"/>
<path d="M23.3651 10.302L23.0373 11.2382L23.8219 10.6544V10.6443V10.6544L24.6064 11.2382L24.2787 10.302L24.2688 10.2919L25.0831 9.74831H24.1099L23.8417 8.802L23.5438 9.74831H22.5607L23.375 10.2919L23.3651 10.302Z" fill="white"/>
<path d="M25.5001 13.0302L25.1724 13.9665L25.947 13.3725H25.9569L26.7415 13.9665L26.4138 13.0302L26.4038 13.0201L27.2082 12.4665H26.2449L25.9768 11.5302L25.6789 12.4665V12.4765L25.669 12.4665H24.6957L25.5001 13.0201H25.5101L25.5001 13.0302Z" fill="white"/>
<path d="M25.5001 16.5235L25.1724 17.4698L25.947 16.8759H25.9569L26.7415 17.4698L26.4138 16.5235H26.4038L27.2082 15.9698H26.2449L25.9768 15.0336L25.6789 15.9698H25.669H24.6957L25.5001 16.5235H25.5101H25.5001Z" fill="white"/>
<path d="M23.2161 19.1913L22.8884 20.1376L23.6729 19.5436H23.6828L24.4574 20.1376L24.1297 19.1913H24.1198H24.1297L24.9341 18.6376H23.9609L23.7027 17.7014L23.3948 18.6376H22.4216L23.226 19.1913H23.2161Z" fill="white"/>
<path d="M21.0611 20.7215L21.3888 21.6577L20.6043 21.0738V21.0638V21.0738L19.8198 21.6577L20.1475 20.7215L20.1574 20.7114H20.1475L19.3431 20.1678H20.3163L20.5844 19.2215L20.8824 20.1678H21.8655L21.0512 20.7114L21.0611 20.7215Z" fill="white"/>
</g>
</g>
<defs>
<filter id="filter0_d_42_548" x="0" y="0" width="41" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_42_548"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_42_548" result="shape"/>
</filter>
<clipPath id="clip0_42_548">
<rect x="4" y="4" width="33" height="21" rx="3" fill="white"/>
</clipPath>
</defs>
</svg>

</template>