<template>
  <section class=" ">
    <div
      class="lg:border-[1px] border-[#E1E1E1] rounded-2xl pt-6 pb-7 lg:pr-3 lg:pl-8 relative h-full w-full"
    >
      <div class="grid gap-[10px] lg:gap-7 px-4 lg:px-0 mb-[10px] lg:mb-[30px]">
        <p class="text-xl lg:text-3xl">
          {{ t("Курс валют на ")
          }}<span class="text-[#717171]">{{ t("сегодня") }}</span>
        </p>
        <div
          class="grid md:grid-cols-6 font-sans grid-cols-11 lg:text-base text-sm text-[#878787]"
        >
          <div class="col-start-1 col-end-3">{{ t("Валюта") }}</div>
          <div class="col-start-3 col-end-5 hidden md:block">
            {{ t("Банк продаёт") }}
          </div>
          <div class="col-start-6 col-end-9 md:hidden block">
            {{ t("Продаём") }}
          </div>
          <div class="col-start-5 col-end-7 hidden md:block">
            {{ t("Банк покупает") }}
          </div>
          <div class="col-start-9 col-end-12 md:hidden block">
            {{ t("Покупаем") }}
          </div>
        </div>
      </div>
      <div class="lg:hidden font-sans block">
        <div
          v-for="(currency, index) in currencies"
          :key="index"
          :class="getClassForCurrency(index)"
          class="grid items-center col-start-1 col-end-12 md:col-end-7 grid-cols-11 md:grid-cols-6 py-[6px] px-4 lg:px-0"
        >
          <div class="col-start-1 items-center col-end-6 flex gap-3">
            <component :is="currency.icon"></component>
            <div class="font-sans leading-none">{{ currency.fullName }}</div>
          </div>
          <div class="col-start-6 col-end-9">
            {{ formatMoney(currency.sale) }}
          </div>
          <div class="col-start-9 col-end-12">
            {{ formatMoney(currency.buy) }}
          </div>
        </div>
      </div>
      <div class="hidden font-sans lg:block">
        <div
          v-for="(currency, index) in currencies"
          :key="index"
          :class="getClassForCurrency(index)"
          class="grid items-center col-start-1 col-end-7 grid-cols-6 py-[6px] px-4 lg:px-0"
        >
          <div class="col-start-1 items-center col-end-3 flex gap-3">
            <component :is="currency.icon"></component>
            <div class="font-sans leading-none">{{ currency.fullName }}</div>
          </div>
          <div class="col-start-3 col-end-5">
            {{ formatMoney(currency.sale) }}
          </div>
          <div class="col-start-5 col-end-7">
            {{ formatMoney(currency.buy) }}
          </div>
        </div>
      </div>

      <!-- <button
        @click="toggleShowAll"
        class="lg:hidden block mt-4 right-16 info-link absolute"
      >
        {{ showAll ? t("Скрыть дополнительные курсы") : t("Все курсы") }}
      </button> -->
    </div>
  </section>
</template>

<script setup>
import { ref, computed } from "vue";

import usd from "@/components/a-icon/a-icon-usd.vue";
import eur from "@/components/a-icon/a-icon-eur.vue";
import tur from "@/components/a-icon/a-icon-try.vue";
import cny from "@/components/a-icon/a-icon-cny.vue";
import gbp from "@/components/a-icon/a-icon-gbp.vue";
import jpy from "@/components/a-icon/a-icon-jpy.vue";
import aed from "@/components/a-icon/a-icon-aed.vue";

const { data: exchange } = await useAsyncData(
  "get all rates",
  () => $fetch("/api/v1/exchange-rates"),
  { lazy: true, default: () => [] }
);

const { t } = useI18n({ useScope: "local" });

const { $get } = useAxios();

const { data: exchanges } = await useAsyncData(
  "get all rates",
  () => $fetch("https://amra-bank.com/api/v1/exchange-rates"),
  { lazy: true, default: () => [] }
);

// const defaultCurrency = ref([
//   {
//     name: "EUR",
//     operation: "Покупка",
//     amount: "97.2",
//     date: "2024-08-21T11:27:31",
//   },
//   {
//     name: "EUR",
//     operation: "Продажа",
//     amount: "102.2",
//     date: "2024-08-21T11:27:31",
//   },
//   {
//     name: "TRY",
//     operation: "Покупка",
//     amount: "2",
//     date: "2024-08-21T11:27:31",
//   },
//   {
//     name: "TRY",
//     operation: "Продажа",
//     amount: "4",
//     date: "2024-08-21T11:27:31",
//   },
//   {
//     name: "USD",
//     operation: "Покупка",
//     amount: "90.2",
//     date: "2024-08-21T11:27:31",
//   },
//   {
//     name: "USD",
//     operation: "Продажа",
//     amount: "95",
//     date: "2024-08-21T11:27:31",
//   },
// ]);

const currencies = computed(() => {
  if (!!exchanges.value && exchanges.value.length) {
    const result = exchanges.value.reduce((acc, curr) => {
      const existingItem = acc.find((item) => item.name === curr.name);
      if (existingItem) {
        if (curr.operation === "Продажа") {
          existingItem.sale = curr.amount;
        } else {
          existingItem.buy = curr.amount;
        }
      } else {
        let data = determineTheCurrency(curr.name);
        acc.push({
          name: curr.name,
          fullName: data.name,
          ...(curr.operation === "Продажа"
            ? { sale: curr.amount }
            : { buy: curr.amount }),
          icon: data.icon,
        });
      }
      return acc;
    }, []);
    return result;
  } else {
    return [];
  }
});

function determineTheCurrency(name) {
  let currency = {
    name: "",
    icon: null,
  };

  switch (name) {
    case "EUR": {
      (currency.name = "EUR/RUB"), (currency.icon = eur);
      break;
    }
    case "TRY": {
      (currency.name = "TRY/RUB"), (currency.icon = tur);
      break;
    }
    case "USD": {
      (currency.name = "USD/RUB"), (currency.icon = usd);
      break;
    }
    default: {
      (currency.name = "EUR/RUB"), (currency.icon = eur);
    }
  }
  return currency;
}

function formatMoney(number) {
  return new Intl.NumberFormat("ru-RU", {
    // style: "currency",
    currency: "RUB",
    minimumFractionDigits: 2,
  }).format(number);
}

const showAll = ref(false);

const toggleShowAll = () => {
  showAll.value = !showAll.value;
};

const displayedCurrencies = computed(() => {
  return showAll.value ? currencies : currencies.slice(0, 3);
});

const getClassForCurrency = (index) => {
  return index % 2 !== 1 ? "bg-[#F3F3F8BF]" : "";
};
</script>
<style scoped>
.info-link {
  color: inherit;
  text-decoration: none;
}

.info-link {
  text-decoration: underline;
}
</style>
<i18n lang="json">
{
  "ru": {
    "Курс валют на": "Курс валют на",
    "сегодня": "сегодня",
    "Валюта": "Валюта",
    "Банк продаёт": "Банк продаёт",
    "Банк покупает": "Банк покупает",
    "Скрыть дополнительные курсы": "Скрыть дополнительные курсы",
    "Все курсы": "Все курсы"
  },
  "en": {
    "Курс валют на": "Exchange Rates for",
    "сегодня": "Today",
    "Валюта": "Currency",
    "Банк продаёт": "Bank sales",
    "Банк покупает": "Bank Buys",
    "Скрыть дополнительные курсы": "Hide Additional Rates",
    "Все курсы": "All Rates"
  },
  "es": {
    "Курс валют на": "Tipos de cambio para",
    "сегодня": "hoy",
    "Валюта": "Moneda",
    "Банк продаёт": "El banco vende",
    "Банк покупает": "El banco compra",
    "Скрыть дополнительные курсы": "Ocultar tipos de cambio adicionales",
    "Все курсы": "Todos los tipos de cambio"
  },
  "ar": {
    "Курс валют на": "أسعار الصرف ل",
    "сегодня": "اليوم",
    "Валюта": "العملة",
    "Банк продаёт": "البنك يبيع",
    "Банк покупает": "البنك يشتري",
    "Скрыть дополнительные курсы": "إخفاء أسعار الصرف الإضافية",
    "Все курсы": "جميع الأسعار"
  },
  "tr": {
    "Курс валют на": "Bugünün Döviz Kurları için",
    "сегодня": "Bugün",
    "Валюта": "Para Birimi",
    "Банк продаёт": "Banka Satıyor",
    "Банк покупает": "Banka Alıyor",
    "Скрыть дополнительные курсы": "Ekstra Kurları Gizle",
    "Все курсы": "Tüm Kurlar"
  }
}
</i18n>
