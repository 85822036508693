<template>
  <svg
    width="41"
    height="29"
    viewBox="0 0 41 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_140_3612)">
      <g clip-path="url(#clip0_140_3612)">
        <path d="M37 10.4646H4V12.0807H37V10.4646Z" fill="#DB2E2E" />
        <path d="M37 7.2323H4V8.84845H37V7.2323Z" fill="#DB2E2E" />
        <path d="M37 4H4V5.61615H37V4Z" fill="#DB2E2E" />
        <path d="M37 13.6969H4V15.313H37V13.6969Z" fill="#DB2E2E" />
        <path d="M37 16.9292H4V18.5453H37V16.9292Z" fill="#DB2E2E" />
        <path d="M37 20.1615H4V21.7776H37V20.1615Z" fill="#DB2E2E" />
        <path d="M37 23.3839H4V25H37V23.3839Z" fill="#DB2E2E" />
        <path d="M37 8.84845H4V10.4646H37V8.84845Z" fill="white" />
        <path d="M37 5.61615H4V7.2323H37V5.61615Z" fill="white" />
        <path d="M37 12.0807H4V13.6969H37V12.0807Z" fill="white" />
        <path d="M37 15.3031H4V16.9192H37V15.3031Z" fill="white" />
        <path d="M37 18.5453H4V20.1615H37V18.5453Z" fill="white" />
        <path d="M37 21.7776H4V23.3938H37V21.7776Z" fill="white" />
        <path d="M16.3353 4.04956H4V13.8357H16.3353V4.04956Z" fill="#2E4593" />
        <path
          d="M14.3136 5.99294L14.4388 6.36971L14.1306 6.13175L13.8225 6.36971L13.9477 5.99294L13.6299 5.77481H14.0151L14.121 5.38812L14.2365 5.77481H14.2462H14.6313L14.3136 5.99294H14.3039H14.3136Z"
          fill="white"
        />
        <path
          d="M14.3136 7.97594L14.4388 8.35271L14.1306 8.11475L13.8225 8.35271L13.9477 7.97594V7.96602L13.6299 7.74789H14.0151L14.121 7.36121L14.2365 7.74789H14.2462H14.6313L14.3136 7.96602H14.3039L14.3136 7.97594Z"
          fill="white"
        />
        <path
          d="M14.3136 9.94902L14.4388 10.3258L14.1306 10.0878L13.8225 10.3258L13.9477 9.94902L13.6299 9.72098H14.0151L14.121 9.34421L14.2365 9.72098H14.2462H14.6313L14.3136 9.94902H14.3039H14.3136Z"
          fill="white"
        />
        <path
          d="M14.3136 11.9221L14.4388 12.2989L14.1306 12.0609L13.8225 12.2989L13.9477 11.9221L13.6299 11.6941H14.0151L14.121 11.3173L14.2365 11.6941V11.704L14.2462 11.6941H14.6313L14.3136 11.9221H14.3039H14.3136Z"
          fill="white"
        />
        <path
          d="M12.2716 5.99294L12.4064 6.36971L12.0886 6.13175L11.7805 6.36971L11.9057 5.99294L11.5879 5.77481H11.9731L12.079 5.38812L12.2042 5.77481H12.1945H12.2042H12.5893L12.2716 5.99294H12.2619H12.2716Z"
          fill="white"
        />
        <path
          d="M12.2716 7.97594L12.4064 8.35271L12.0886 8.11475L11.7805 8.35271L11.9057 7.97594V7.96602L11.5879 7.74789H11.9731L12.079 7.36121L12.2042 7.74789H12.1945H12.2042H12.5893L12.2716 7.96602H12.2619L12.2716 7.97594Z"
          fill="white"
        />
        <path
          d="M12.2716 9.94902L12.4064 10.3258L12.0886 10.0878L11.7805 10.3258L11.9057 9.94902L11.5879 9.72098H11.9731L12.079 9.34421L12.2042 9.72098H12.1945H12.2042H12.5893L12.2716 9.94902H12.2619H12.2716Z"
          fill="white"
        />
        <path
          d="M12.2716 11.9221L12.4064 12.2989L12.0886 12.0609L11.7805 12.2989L11.9057 11.9221L11.5879 11.6941H11.9731L12.079 11.3173L12.2042 11.6941L12.1945 11.704L12.2042 11.6941H12.5893L12.2716 11.9221H12.2619H12.2716Z"
          fill="white"
        />
        <path
          d="M10.2306 5.99294L10.3654 6.36971L10.0476 6.13175L9.73946 6.36971L9.86465 5.99294H9.87427H9.86465L9.54688 5.77481H9.93205L10.038 5.38812L10.1632 5.77481H10.1535H10.1632H10.5483L10.2306 5.99294Z"
          fill="white"
        />
        <path
          d="M10.2306 7.97594L10.3654 8.35271L10.0476 8.11475L9.73946 8.35271L9.86465 7.97594L9.87427 7.96602H9.86465L9.54688 7.74789H9.93205L10.038 7.36121L10.1632 7.74789H10.1535H10.1632H10.5483L10.2306 7.96602V7.97594Z"
          fill="white"
        />
        <path
          d="M10.2306 9.94902L10.3654 10.3258L10.0476 10.0878L9.73946 10.3258L9.86465 9.94902H9.87427H9.86465L9.54688 9.72098H9.93205L10.038 9.34421L10.1632 9.72098H10.1535H10.1632H10.5483L10.2306 9.94902Z"
          fill="white"
        />
        <path
          d="M10.2306 11.9221L10.3654 12.2989L10.0476 12.0609L9.73946 12.2989L9.86465 11.9221H9.87427H9.86465L9.54688 11.6941H9.93205L10.038 11.3173L10.1632 11.6941L10.1535 11.704L10.1632 11.6941H10.5483L10.2306 11.9221Z"
          fill="white"
        />
        <path
          d="M8.2275 5.99294L8.35268 6.36971L8.04454 6.13175L7.72677 6.36971L7.86158 5.99294L7.53418 5.77481H7.92899L8.03491 5.38812L8.15046 5.77481H8.54527L8.21787 5.99294H8.2275Z"
          fill="white"
        />
        <path
          d="M8.2275 7.97594L8.35268 8.35271L8.04454 8.11475L7.72677 8.35271L7.86158 7.97594V7.96602L7.53418 7.74789H7.92899L8.03491 7.36121L8.15046 7.74789H8.54527L8.21787 7.96602L8.2275 7.97594Z"
          fill="white"
        />
        <path
          d="M8.2275 9.94902L8.35268 10.3258L8.04454 10.0878L7.72677 10.3258L7.86158 9.94902L7.53418 9.72098H7.92899L8.03491 9.34421L8.15046 9.72098H8.54527L8.21787 9.94902H8.2275Z"
          fill="white"
        />
        <path
          d="M8.2275 11.9221L8.35268 12.2989L8.04454 12.0609L7.72677 12.2989L7.86158 11.9221L7.53418 11.6941H7.92899L8.03491 11.3173L8.15046 11.6941V11.704V11.6941H8.54527L8.21787 11.9221H8.2275Z"
          fill="white"
        />
        <path
          d="M6.21494 5.99294L6.34012 6.36971L6.03198 6.13175L5.72384 6.36971L5.84902 5.99294L5.53125 5.77481H5.91643L6.02235 5.38812L6.14753 5.77481H6.53271L6.21494 5.99294H6.20531H6.21494Z"
          fill="white"
        />
        <path
          d="M6.21494 7.97594L6.34012 8.35271L6.03198 8.11475L5.72384 8.35271L5.84902 7.97594V7.96602L5.53125 7.74789H5.91643L6.02235 7.36121L6.14753 7.74789H6.53271L6.21494 7.96602H6.20531L6.21494 7.97594Z"
          fill="white"
        />
        <path
          d="M6.21494 9.94902L6.34012 10.3258L6.03198 10.0878L5.72384 10.3258L5.84902 9.94902L5.53125 9.72098H5.91643L6.02235 9.34421L6.14753 9.72098H6.53271L6.21494 9.94902H6.20531H6.21494Z"
          fill="white"
        />
        <path
          d="M6.21494 11.9221L6.34012 12.2989L6.03198 12.0609L5.72384 12.2989L5.84902 11.9221L5.53125 11.6941H5.91643L6.02235 11.3173L6.14753 11.6941V11.704V11.6941H6.53271L6.21494 11.9221H6.20531H6.21494Z"
          fill="white"
        />
        <path
          d="M15.3058 6.98442L15.4309 7.36119L15.1228 7.12323L14.805 7.36119L14.9398 6.98442V6.97451L14.6221 6.75638H15.0072L15.1132 6.36969L15.2287 6.75638H15.6235L15.2961 6.97451L15.3058 6.98442Z"
          fill="white"
        />
        <path
          d="M15.3058 8.95751L15.4309 9.33428L15.1228 9.09632L14.805 9.33428L14.9398 8.95751L14.6221 8.72946H15.0072L15.1132 8.35269L15.2287 8.72946H15.6235L15.2961 8.95751H15.3058Z"
          fill="white"
        />
        <path
          d="M15.3058 10.9405L15.4309 11.3173L15.1228 11.0793L14.805 11.3173L14.9398 10.9405V10.9306L14.6221 10.7125H15.0072L15.1132 10.3258L15.2287 10.7125H15.6235L15.2961 10.9306L15.3058 10.9405Z"
          fill="white"
        />
        <path
          d="M15.3058 12.9136L15.4309 13.3003L15.1228 13.0524L14.805 13.3003L14.9398 12.9136L14.6221 12.6955H15.0072L15.1132 12.3088L15.2287 12.6955H15.6235L15.2961 12.9136H15.3058Z"
          fill="white"
        />
        <path
          d="M15.3058 5.01134L15.4309 5.38811L15.1228 5.16007V5.15015V5.16007L14.805 5.38811L14.9398 5.01134L14.6221 4.7833H15.0072L15.1132 4.40652L15.2287 4.7833V4.79321V4.7833H15.6235L15.2961 5.01134H15.3058Z"
          fill="white"
        />
        <path
          d="M13.3028 6.98442L13.4376 7.36119L13.1295 7.12323L12.8117 7.36119L12.9465 6.98442V6.97451L12.6191 6.75638H13.0139L13.1199 6.36969L13.2354 6.75638H13.6302L13.3028 6.97451V6.98442Z"
          fill="white"
        />
        <path
          d="M13.3028 8.95751L13.4376 9.33428L13.1295 9.09632L12.8117 9.33428L12.9465 8.95751L12.6191 8.72946H13.0139L13.1199 8.35269L13.2354 8.72946H13.6302L13.3028 8.95751Z"
          fill="white"
        />
        <path
          d="M13.3028 10.9405L13.4376 11.3173L13.1295 11.0793L12.8117 11.3173L12.9465 10.9405V10.9306L12.6191 10.7125H13.0139L13.1199 10.3258L13.2354 10.7125H13.6302L13.3028 10.9306V10.9405Z"
          fill="white"
        />
        <path
          d="M13.3028 12.9136L13.4376 13.3003L13.1295 13.0524L12.8117 13.3003L12.9465 12.9136L12.6191 12.6955H13.0139L13.1199 12.3088L13.2354 12.6955H13.6302L13.3028 12.9136Z"
          fill="white"
        />
        <path
          d="M13.3028 5.01134L13.4376 5.38811L13.1295 5.16007V5.15015V5.16007L12.8117 5.38811L12.9465 5.01134L12.6191 4.7833H13.0139L13.1199 4.40652L13.2354 4.7833V4.79321V4.7833H13.6302L13.3028 5.01134Z"
          fill="white"
        />
        <path
          d="M11.2325 6.98442L11.3673 7.36119L11.0496 7.12323L10.7414 7.36119L10.8666 6.98442V6.97451L10.5488 6.75638H10.934L11.0399 6.36969L11.1555 6.75638H11.1651H11.5503L11.2325 6.97451H11.2229L11.2325 6.98442Z"
          fill="white"
        />
        <path
          d="M11.2325 8.95751L11.3673 9.33428L11.0496 9.09632L10.7414 9.33428L10.8666 8.95751L10.5488 8.72946H10.934L11.0399 8.35269L11.1555 8.72946H11.1651H11.5503L11.2325 8.95751H11.2229H11.2325Z"
          fill="white"
        />
        <path
          d="M11.2325 10.9405L11.3673 11.3173L11.0496 11.0793L10.7414 11.3173L10.8666 10.9405V10.9306L10.5488 10.7125H10.934L11.0399 10.3258L11.1555 10.7125H11.1651H11.5503L11.2325 10.9306H11.2229L11.2325 10.9405Z"
          fill="white"
        />
        <path
          d="M11.2325 12.9136L11.3673 13.3003L11.0496 13.0524L10.7414 13.3003L10.8666 12.9136L10.5488 12.6955H10.934L11.0399 12.3088L11.1555 12.6955H11.1651H11.5503L11.2325 12.9136H11.2229H11.2325Z"
          fill="white"
        />
        <path
          d="M11.2325 5.01134L11.3673 5.38811L11.0496 5.16007V5.15015V5.16007L10.7414 5.38811L10.8666 5.01134L10.5488 4.7833H10.934L11.0399 4.40652L11.1555 4.7833V4.79321L11.1651 4.7833H11.5503L11.2325 5.01134H11.2229H11.2325Z"
          fill="white"
        />
        <path
          d="M9.20029 6.98442L9.3351 7.36119L9.01733 7.12323L8.70919 7.36119L8.83437 6.98442L8.844 6.97451H8.83437L8.5166 6.75638H8.90178L9.0077 6.36969L9.13288 6.75638H9.51806L9.20029 6.97451V6.98442Z"
          fill="white"
        />
        <path
          d="M9.20029 8.95751L9.3351 9.33428L9.01733 9.09632L8.70919 9.33428L8.83437 8.95751H8.844H8.83437L8.5166 8.72946H8.90178L9.0077 8.35269L9.13288 8.72946H9.51806L9.20029 8.95751Z"
          fill="white"
        />
        <path
          d="M9.20029 10.9405L9.3351 11.3173L9.01733 11.0793L8.70919 11.3173L8.83437 10.9405L8.844 10.9306H8.83437L8.5166 10.7125H8.90178L9.0077 10.3258L9.13288 10.7125H9.51806L9.20029 10.9306V10.9405Z"
          fill="white"
        />
        <path
          d="M9.20029 12.9136L9.3351 13.3003L9.01733 13.0524L8.70919 13.3003L8.83437 12.9136H8.844H8.83437L8.5166 12.6955H8.90178L9.0077 12.3088L9.13288 12.6955H9.51806L9.20029 12.9136Z"
          fill="white"
        />
        <path
          d="M9.20029 5.01134L9.3351 5.38811L9.01733 5.16007V5.15015V5.16007L8.70919 5.38811L8.83437 5.01134H8.844H8.83437L8.5166 4.7833H8.90178L9.0077 4.40652L9.13288 4.7833V4.79321V4.7833H9.51806L9.20029 5.01134Z"
          fill="white"
        />
        <path
          d="M7.21689 6.98442L7.34207 7.36119L7.03393 7.12323L6.71616 7.36119L6.85097 6.98442V6.97451L6.5332 6.75638H6.91838L7.0243 6.36969L7.13986 6.75638H7.53466L7.20726 6.97451L7.21689 6.98442Z"
          fill="white"
        />
        <path
          d="M7.21689 8.95751L7.34207 9.33428L7.03393 9.09632L6.71616 9.33428L6.85097 8.95751L6.5332 8.72946H6.91838L7.0243 8.35269L7.13986 8.72946H7.53466L7.20726 8.95751H7.21689Z"
          fill="white"
        />
        <path
          d="M7.21689 10.9405L7.34207 11.3173L7.03393 11.0793L6.71616 11.3173L6.85097 10.9405V10.9306L6.5332 10.7125H6.91838L7.0243 10.3258L7.13986 10.7125H7.53466L7.20726 10.9306L7.21689 10.9405Z"
          fill="white"
        />
        <path
          d="M7.21689 12.9136L7.34207 13.3003L7.03393 13.0524L6.71616 13.3003L6.85097 12.9136L6.5332 12.6955H6.91838L7.0243 12.3088L7.13986 12.6955H7.53466L7.20726 12.9136H7.21689Z"
          fill="white"
        />
        <path
          d="M7.21689 5.01134L7.34207 5.38811L7.03393 5.16007V5.15015V5.16007L6.71616 5.38811L6.85097 5.01134L6.5332 4.7833H6.91838L7.0243 4.40652L7.13986 4.7833V4.79321V4.7833H7.53466L7.20726 5.01134H7.21689Z"
          fill="white"
        />
        <path
          d="M5.19443 6.98442L5.31961 7.36119L5.01147 7.12323L4.70333 7.36119L4.82851 6.98442L4.83814 6.97451H4.82851L4.51074 6.75638H4.89592L5.00184 6.36969L5.1174 6.75638H5.5122L5.19443 6.97451V6.98442Z"
          fill="white"
        />
        <path
          d="M5.19443 8.95751L5.31961 9.33428L5.01147 9.09632L4.70333 9.33428L4.82851 8.95751H4.83814H4.82851L4.51074 8.72946H4.89592L5.00184 8.35269L5.1174 8.72946H5.5122L5.19443 8.95751Z"
          fill="white"
        />
        <path
          d="M5.19443 10.9405L5.31961 11.3173L5.01147 11.0793L4.70333 11.3173L4.82851 10.9405L4.83814 10.9306H4.82851L4.51074 10.7125H4.89592L5.00184 10.3258L5.1174 10.7125H5.5122L5.19443 10.9306V10.9405Z"
          fill="white"
        />
        <path
          d="M5.19443 12.9136L5.31961 13.3003L5.01147 13.0524L4.70333 13.3003L4.82851 12.9136H4.83814H4.82851L4.51074 12.6955H4.89592L5.00184 12.3088L5.1174 12.6955H5.5122L5.19443 12.9136Z"
          fill="white"
        />
        <path
          d="M5.19443 5.01134L5.31961 5.38811L5.01147 5.16007V5.15015V5.16007L4.70333 5.38811L4.82851 5.01134H4.83814H4.82851L4.51074 4.7833H4.89592L5.00184 4.40652L5.1174 4.7833V4.79321V4.7833H5.5122L5.19443 5.01134Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_140_3612"
        x="0"
        y="0"
        width="41"
        height="29"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_140_3612"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_140_3612"
          result="shape"
        />
      </filter>
      <clipPath id="clip0_140_3612">
        <rect x="4" y="4" width="33" height="21" rx="3" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
