<template>
    <section class="container">
        <div class="lg:grid grid-cols-11 gap-10">
            <div class="col-start-1 col-end-6 lg:mb-0 mb-10">
                <ClientOnly>
                    <ACurrency class="h-full w-full"></ACurrency>
                </ClientOnly>
            </div>
            <div class="col-start-6 col-end-12">
                <div
                    class="lg:border-[1px] border-[#E1E1E1] rounded-2xl lg:p-6 relative px-4 w-full h-full">
                    <p class="text-xl lg:text-3xl mb-[10px]">
                        {{ t("Офисы и банкоматы") }}
                    </p>
                    <p class="text-[#878787] text-sm lg:text-base mb-[25px]">
                        {{ t("Адреса отделений и банкоматов Амра-Банка на карте") }} —
                        <br class="hidden lg:block" />{{ t("график работы, телефоны") }}.
                    </p>
                  <AMap class="!h-[288px]"></AMap>
                    <nuxt-link :to="localePath('/offices')">
                        <AButton type="map"
                            class="!absolute right-10 bottom-10 text-lg !font-medium !w-68 lg:!w-36 hidden sm:block">
                            {{ t("Перейти") }}</AButton>
                    </nuxt-link>
                    <div class="px-3"> 
                        <nuxt-link :to="localePath('/offices')" class="">
                            <AButton width="mobile"
                                class="bottom-16 absolute opacity-85 text-lg !font-medium sm:hidden block max-w-98 !border-none">
                                {{ t("Перейти") }}
                            </AButton>
                        </nuxt-link>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
const { t } = useI18n({ useScope: "local" });
</script>

<i18n lang="json">{
    "ru": {
        "Офисы и банкоматы": "Офисы и банкоматы",
        "Адреса отделений и банкоматов Амра-Банка на карте": "Адреса отделений и банкоматов Амра-Банка на карте",
        "график работы, телефоны": "график работы, телефоны",
        "Перейти": "Перейти"
    },
    "en": {
        "Офисы и банкоматы": "Offices and ATMs",
        "Адреса отделений и банкоматов Амра-Банка на карте": "Addresses of Amra Bank branches and ATMs on the map",
        "график работы, телефоны": "working hours, phone numbers",
        "Перейти": "Go"
    },
    "es": {
        "Офисы и банкоматы": "Oficinas y cajeros automáticos",
        "Адреса отделений и банкоматов Амра-Банка на карте": "Direcciones de las sucursales y cajeros automáticos de Amra Bank en el mapa",
        "график работы, телефоны": "horario de trabajo, números de teléfono",
        "Перейти": "Ir"
    },
    "ar": {
        "Офисы и банкоматы": "المكاتب وأجهزة الصراف الآلي",
        "Адреса отделений и банкоматов Амра-Банка на карте": "عناوين فروع وأجهزة الصراف الآلي لبنك عمرا على الخريطة",
        "график работы, телефоны": "ساعات العمل، أرقام الهاتف",
        "Перейти": "اذهب"
    },
    "tr": {
        "Офисы и банкоматы": "Ofisler ve ATM'ler",
        "Адреса отделений и банкоматов Амра-Банка на карте": "Amra Bank şubeleri ve ATM'lerinin adresleri haritada",
        "график работы, телефоны": "çalışma saatleri, telefon numaraları",
        "Перейти": "Git"
    }
}</i18n>
