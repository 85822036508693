<template>
    <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_43_561)">
<g clip-path="url(#clip0_43_561)">
<path d="M36.9901 4.04999V24.9797L36.9802 24.9898H4.02972V4.04999H36.9901Z" fill="#2E4593"/>
<path d="M36.9901 22.8016V24.9799L36.9802 24.99H33.5524L23.4374 18.5554L22.4269 17.913L21.2282 17.1601L20.8319 16.8991L19.6133 16.1262L19.1576 15.8351L18.7812 15.6042L18.0183 15.1123L17.7706 14.9618L17.3942 14.7208L13.8079 12.4321L12.193 11.4082L4.02972 6.22849V4.05019L4.05944 4L4.11889 4.05019H7.45752L17.7706 10.5951L18.7812 11.2376L19.0486 11.4082L20.6734 12.4321L20.8319 12.5425L21.4461 12.934L22.4269 13.5564L22.5953 13.6568L23.4374 14.1989L24.2696 14.7208L26.4788 16.1262L28.1036 17.1601L36.9901 22.8016Z" fill="#FCF5FF"/>
<path d="M36.9901 24.9197L37 25L34.7908 24.99L23.4374 17.7725L22.4665 17.1601L22.4269 17.13L21.4461 16.5076L20.8616 16.1262L19.7818 15.4436L18.7811 14.8112L18.6425 14.7208L18.0183 14.3294L17.7706 14.1687L15.0462 12.4321L13.4314 11.4082L4.02972 5.43547V4H4.05944L4.11889 4.05019L15.7199 11.4082L17.3248 12.4321L17.7706 12.7132L18.7811 13.3657L19.1576 13.6066L19.7818 13.9981L20.921 14.7208L22.4269 15.6745L22.5953 15.7849L23.1402 16.1262L23.4374 16.3169L24.755 17.1601L36.9901 24.9197Z" fill="#F42525"/>
<path d="M36.9901 4.46156V6.63985L29.4707 11.408L27.8559 12.4319L24.2696 14.7206L23.4374 15.2426L22.5953 15.7847L22.4269 15.8851L22.0405 16.126L21.4461 16.5074L20.8319 16.8989L20.4257 17.1599L18.7812 18.2039L17.7706 18.8464L8.10147 24.9898H4.02972V23.213L13.5602 17.1599L15.175 16.126L17.3942 14.7206L17.7706 14.4898L18.0183 14.3291L18.7812 13.8373L19.1576 13.6064L20.8319 12.5423L20.9904 12.4319L22.4269 11.5285L22.6052 11.408L23.4374 10.886L34.2062 4.04999H36.7325L36.9901 4.46156Z" fill="#FCF5FF"/>
<path d="M36.9009 4.57198L26.1321 11.408L24.5173 12.4319L23.4374 13.1246L22.5953 13.6566L22.4269 13.767L20.921 14.7206L19.7818 15.4434L19.1576 15.8349L18.7812 16.0858L18.7019 16.126L17.7706 16.7283L17.0871 17.1599L4.77274 24.9898H4.02972V24.006L14.8085 17.1599L16.4233 16.126L17.7706 15.2727L18.0183 15.1121L18.6425 14.7206L18.7812 14.6303L19.7818 13.9979L21.4461 12.9338L22.2288 12.4319L22.4269 12.3115L23.4374 11.669L23.8436 11.408L35.4347 4.04999H36.5839H36.9901V4.50171L36.9009 4.57198Z" fill="#F42525"/>
<path d="M37 11.4082H4V17.1501H37V11.4082Z" fill="white"/>
<path d="M37 12.4321H4V16.1262H37V12.4321Z" fill="#F42525"/>
<path d="M22.4269 4.04999H18.7811V24.9898H22.4269V4.04999Z" fill="#CD002A"/>
<path d="M22.417 17.1599H23.4275V24.9898H17.7607V17.1599H18.7712V11.408H17.7607V4.04999H23.4275V11.408H22.417V17.1599Z" fill="white"/>
<path d="M22.4368 4.04999H18.7515V24.9898H22.4368V4.04999Z" fill="#F42525"/>
<g clip-path="url(#clip1_43_561)">
<path d="M36.9207 3.96002H4V24.8998H36.9207V3.96002Z" fill="#E53030"/>
<path d="M19.8412 12.1312L22.8529 16.7387L17.8004 14.8013L23.0114 13.3759L19.6034 17.6221L19.8412 12.1312Z" fill="white"/>
<path d="M18.5136 17.5719C17.5725 19.2182 16.235 19.9008 14.2239 19.9008C11.2023 19.9008 8.75531 17.4213 8.75531 14.3597C8.75531 11.298 11.2023 8.81854 14.2239 8.81854C16.5025 8.81854 18.2164 9.95286 19.0288 11.9605C18.266 10.5752 17.0573 9.92275 15.383 9.92275C12.9261 9.92275 10.9348 11.9404 10.9348 14.4299C10.9348 16.9194 12.9261 18.9371 15.383 18.9371C16.8295 18.9371 17.7013 18.656 18.5136 17.5619V17.5719Z" fill="white"/>
</g>
</g>
</g>
<defs>
<filter id="filter0_d_43_561" x="0" y="0" width="41" height="29" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_43_561"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_43_561" result="shape"/>
</filter>
<clipPath id="clip0_43_561">
<rect x="4" y="4" width="33" height="21" rx="3" fill="white"/>
</clipPath>
<clipPath id="clip1_43_561">
<rect width="32.9207" height="20.9398" fill="white" transform="translate(4 3.96002)"/>
</clipPath>
</defs>
</svg>

</template>